import React from "react";
import { useEffect } from "react";
import img1 from "../../Data/concurso21julio/page-0001.webp";
import img2 from "../../Data/concurso21julio/page-0002.webp";
import img3 from "../../Data/concurso21julio/page-0003.webp";
import img4 from "../../Data/concurso21julio/page-0004.webp";
import img5 from "../../Data/concurso21julio/page-0005.webp";
import img6 from "../../Data/concurso21julio/page-0006.webp";

function Novedades() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content-novedades">
      <div className="img-concurso">
      <img src={img1} alt="" />
        <img src={img2} alt="" />
        <img src={img3} alt="" />
        <img src={img4} alt="" />
        <img src={img5} alt="" />
        <img src={img6} alt="" />
      </div>

      <div className="book">
        <div className="gap"></div>
        <div className="pages">
          <div className="page"></div>
          <div className="page"></div>
          <div className="page"></div>
          <div className="page"></div>
          <div className="page"></div>
          <div className="page"></div>
        </div>
        <div className="flips">
          <div className="flip flip1">
            <div className="flip flip2">
              <div className="flip flip3">
                <div className="flip flip4">
                  <div className="flip flip5">
                    <div className="flip flip6">
                      <div className="flip flip7"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Novedades;
